export default [
  {
    header: 'MAIN FUNCTIONS',
    action: 'read',
    resource: 'basic',
  },
  {
    title: 'Member List',
    route: 'member-list',
    icon: 'UsersIcon',
    // acl: {
    action: 'read',
    resource: 'basic',
    // },
  },
  {
    title: 'Schedule',
    route: 'calendar',
    icon: 'CalendarIcon',
    // acl: {
    action: 'read',
    resource: 'basic',
    action: 'read',
    resource: 'package',
    // },
  },

  {
    header: 'Report',
    action: 'read',
    resource: 'basic',
  },
  {
    title: 'Daily Sales',
    icon: 'PieChartIcon',
    route: 'report',
    action: 'read',
    resource: 'manager',
  },
  {
    title: 'Transaction',
    icon: 'PieChartIcon',
    route: 'report-transacion',
    action: 'read',
    resource: 'manager',
  },

  {
    title: 'Online Payment',
    icon: 'PieChartIcon',
    route: 'report-online-payment',
    action: 'read',
    resource: 'credit_card',
    resource: 'staff',
  },
  {
    title: 'Entry',
    icon: 'PieChartIcon',
    route: 'report-entry',
    action: 'read',
    resource: 'staff',
  },
  {
    title: 'Unpay',
    icon: 'PieChartIcon',
    route: 'report-nonpay',
    action: 'read',
    resource: 'manager',
  },
  {
    title: 'Commission',
    icon: 'PieChartIcon',
    route: 'report-commission',
    action: 'read',
    resource: 'pt',
  },
  {
    title: 'Roster',
    icon: 'PieChartIcon',
    action: 'read',
    resource: 'roster',
    route: 'report-roster',
  },
  {
    title: 'Product',
    icon: 'PieChartIcon',
    action: 'read',
    resource: 'manager',
    route: 'report-product',
  },
  {
    header: 'Finance',
    action: 'read',
    resource: 'finance',
    action: 'read',
    resource: 'admin',

  },
  {
    title: 'Expenditure',
    route: 'expenditure',
    icon: 'ShoppingBagIcon',
    // acl: {
    action: 'read',
    resource: 'finance',
    action: 'read',
    resource: 'admin',
    // },
  },
  {
    title: 'Invoice',
    route: 'invoice',
    icon: 'DollarSignIcon',
    action: 'read',
    resource: 'finance',
    // acl: {
    action: 'read',
    resource: 'admin',

    // },
  },
  {
    header: 'System Config',
    action: 'read',
    resource: 'staff',
  },
  {
    title: 'User',
    route: 'staff-list',
    icon: 'SmileIcon',
    // acl: {
    action: 'read',
    resource: 'admin',
    // },
  },
  {
    title: '美容室',
    route: 'room-list',
    icon: 'TrelloIcon',
    // acl: {
    action: 'read',
    resource: 'admin',
    // },
  },
  {
    title: 'Product',
    route: 'product-list',
    icon: 'TrelloIcon',
    // acl: {
    action: 'read',
    resource: 'admin',
    // },
  },
  {
    title: 'Package',
    route: 'package-list',
    icon: 'TrelloIcon',
    // acl: {
    action: 'read',
    resource: 'admin',
    // },
  },

]
